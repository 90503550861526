.product-page-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

.product-page-wrapper h1 {
    text-align: left;
    margin-bottom: 0;
}


/* Loading */
.product-page-loading {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.product-page-loading span {
    font-size: 40px;
    color: black;
}


/* Content */
.product-content {
    width: 100%;
    margin: 10px 0;
    display: flex;
}

.product-image {
    width: 50%;
}

.product-information {
    width: calc(50% - 30px);
    margin-left: 30px;
    position: relative;
}

.product-main-image {
    width: 100%;
    height: 300px;
    border: 1px solid #cecece;
    border-radius: 10px;
    overflow: hidden;
}

.product-main-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.product-images {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px -5px;
}

.product-image-item {
    width: 30%;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    border: 3px solid #cecece;
    margin: 0 5px
}

.product-image-item.active {
    border: 3px solid var(--primary);
}

.product-image-item img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.product-information-name {
    font-size: 19px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
    width: calc(100% - 110px);
}

.product-information-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 0;
}

.product-information-item.description {
    flex-direction: column;
}

.product-information-text {
    font-size: 14px;
    color: #222;
    font-weight: bold;
}

.product-information-value {
    font-size: 14px;
    color: #222;
    margin-left: 5px;
}

.product-information-item.description .product-information-value {
    margin-left: 0;
}

.product-view-social-networks {
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-view-social-copy {
    padding: 0 5px;
    cursor: pointer;
}

.product-view-social-item {
    margin-right: 5px;
}

.product-view-social-networks img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.product-information .button {
    max-width: 300px;
    width: 100%;
}


@media screen and (max-width: 600px) {
    .product-content {
        flex-direction: column;
        padding-bottom: 50px;
    }

    .product-image {
        width: 100%;
    }

    .product-information {
        width: 100%;
        margin-left: 0;
        position: relative;
    }

    .product-page-wrapper h1 {
        margin-bottom: 20px;
    }

    .product-information .button {
        max-width: 100%;
        width: 100%;
    }


}