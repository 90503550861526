.home-category {
    position: relative;
    z-index: 10;
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-category-inner {
    height: 200px;
    background: rgb(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);
    max-width: 1200px;
    width: 100%;
    border-radius: 20px;
    margin: 0 20px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.home-category-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: calc(100% / 8 - 10px);
    height: 120px;
    position: relative;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.4s;
}

.home-category-item:hover {
    background: var(--primary);
}

.home-category-item::after {
    content: "";
    width: 1px;
    height: 100px;
    background: #393939;
    position: absolute;
    right: -5px;
}

.home-category-item:last-child::after {
    display: none;
}

.home-category-item img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    filter: invert(1);
}

.home-category-name {
    font-weight: bold;
    color: white;
    font-size: 14px;
    margin-top: 10px;
}


@media screen and (max-width: 900px) {

    .home-category-item {
        width: calc(100% / 4 - 10px);
    }

    .home-category-item:nth-child(4n)::after {
        display: none;
    }

    .home-category-inner {
        height: auto;
    }

}


@media screen and (max-width: 768px) {

    .home-category {
        display: none;
    }

}




/* Catalog */
.home-catalog-wrapper {
    max-width: 1240px;
    margin: 50px auto;
}

.home-catalog-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.home-catalog-products {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.home-catalog-product {
    width: 25%;
    cursor: pointer;
    padding: 20px;
    position: relative;
    border-right: 1px solid #ececec;
    transition: box-shadow 0.3s, background-color 0.3s;
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
}

.home-catalog-product:hover {
    box-shadow: 0 0 10px #c3c3c3;
    background: #fafafa;
}

.home-catalog-product:nth-child(4n) {
    border-right: none
}

.home-catalog-product img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.home-catalog-product-name {
    font-size: 14px;
    font-weight: bold;
    color: #222;
    margin: 15px 0;
    min-height: 35px;
}

.home-catalog-product:hover .home-catalog-product-name {
    color: var(--primary)
}

.home-catalog-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.home-catalog-button {
    border: 1px solid #999;
    width: 180px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 1000;
    border-radius: 10px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
    color: #222;
}

.home-catalog-button:hover {
    color: white;
    background-color: var(--primary);
    box-shadow: 0 0 20px #777;
}

.home-catalog-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.home-catalog-loading span {
    font-size: 40px;
}


@media screen and (max-width: 768px) {
    .home-catalog-product {
        width: 33.333%;
    }
}

@media screen and (max-width: 500px) {

    .home-catalog-product,
    .home-catalog-product:nth-child(4n) {
        width: 50%;
        border: 1px solid #ececec;
        margin-bottom: 0;
    }
}

/* Parts */
.home-parts-wrapper {
    max-width: 1240px;
    padding: 0 10px;
    margin: 70px auto;
}

.home-parts-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.home-parts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.home-parts-item {
    width: calc(100% / 4);
    object-fit: contain;
    padding: 10px;
}

.home-parts-item-inner {
    background: #fcfcfc;
    border: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
    transition: box-shadow 0.4s, transform 0.4s;
    padding: 0 10px;
}

.home-parts-item-inner:hover {
    box-shadow: 0 0 20px #cacaca;
    transform: scale(1.04);
}

.home-parts-item-inner img {
    width: 80px;
    height: 80px;
}

.home-parts-item-text {
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
    color: #222;
}

@media screen and (max-width: 768px) {
    .home-parts-item {
        width: calc(100% / 2);
    }
}

@media screen and (max-width: 500px) {

    .home-parts-wrapper {
        margin: 40px auto;
    }

    .home-parts-wrapper h3 {
        font-size: 22px;
        padding: 0 20px;
    }

    .home-parts-item-inner {
        height: 150px;
    }
}





/* Partners */
.home-partners-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 50px auto;
}

.home-partners-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.home-partners-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-partners-container img {
    width: calc(100% / 5);
    object-fit: contain;
    margin-bottom: 20px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .home-partners-container img {
        width: calc(100% / 3);
    }
}

@media screen and (max-width: 500px) {

    .home-partners-wrapper h3 {
        margin-bottom: 10px;
    }

    .home-partners-container img {
        width: calc(100% / 2);
    }
}



/* Advantages */
.home-advantages {
    background: #f0f0f0;
    padding: 40px 0;
}

.home-advatages-wrapper {
    max-width: 1240px;
    padding: 0 10px;
    margin: 0 auto;
}

.home-advatages-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.home-advatages-container {
    display: flex;
    flex-wrap: wrap;
}

.home-advatages-item {
    width: calc(100% / 4);
    margin-bottom: 20px;
    padding: 10px 20px;
}

.home-advatages-number {
    font-size: 50px;
    text-align: center;
}

.home-advatages-text {
    text-align: center;
    font-size: 14px;
}

@media screen and (max-width: 850px) {
    .home-advatages-number {
        font-size: 36px;
    }
}

@media screen and (max-width: 768px) {
    .home-advatages-item {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {

    .home-advatages-item {
        padding: 10px;
    }

    .home-advatages-number {
        font-size: 30px;
    }
}



/* Brands */
.home-brands-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    margin: 50px auto;
}

.home-brands-wrapper h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 20px;
}

.home-brands-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.home-brands-container img {
    width: calc(100% / 5);
    object-fit: contain;
    margin-bottom: 20px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .home-brands-container img {
        width: calc(100% / 3);
    }
}

@media screen and (max-width: 500px) {

    .home-brands-wrapper h3 {
        margin-bottom: 10px;
    }

    .home-brands-container img {
        width: calc(100% / 2);
    }
}